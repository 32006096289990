
import { useEffect } from "react";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/img/horlamatlogo.png";
import user from "../../assets/img/user.jpg";
import ReactModal from "react-modal";
import io from "socket.io-client";
import { useState } from "react";
import { Notification, Payment } from "../../api";
import Toast from "../Toast";
import notificationSound from "../../assets/audio/notification.mp3";
import { useRef } from "react";

const endpoint = "https://boms-api.softcollections.store";
let socket;

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  
  ReactModal.setAppElement('#root');


const TopNav = () => {

    const logout= (e) => {
        e.preventDefault();
        Cookies.remove("sc_staff");
        navigate("/login");
    }

    const audioRef = useRef(null);

    const navigate = useNavigate();
    const [paymentDetails, setPaymentDetails] = useState({amount: 0, amountWords: "", payerName: "", payerBank: "", paymentDate: "", saleID: "", saleRef: ""});
    const [openModal, setOpenModal] = useState(false);
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        socket = io(endpoint);
        const token = Cookies.get("hv_admin");
        if(!token  || token.length < 1) {
            return navigate("/login");
        }

        socket.on("update_sales_records", (data) => {
            setPaymentDetails(data);
            setOpenModal(true);
            audioRef.current.play();
            Notification.allNotification().then(res => {
                res.notifications && setNotifications(res.notifications);
            })
        })

        Notification.allNotification().then(res => {
            if(res.success) {
                setNotifications(res.notifications);
            }
        })

    }, [])

    //Modal Confirm Payment Button clicked 
  const cofirmPayment = () => {
    const data = {salesID: paymentDetails.saleID, salesRef: paymentDetails.saleRef, status: "Confirmed"};
    Payment.updatePaymentStatus(data).then(res => {
        if(res.error) {
            return Toast.fire({icon: "error", title: res.message});
        }
        Toast.fire({icon: "success", title: "Payment successfully approved."}).then(() => {
            setOpenModal(false);
            socket.emit("payment_confirmed", {salesID: paymentDetails.saleID, salesRef: paymentDetails.saleRef});
        });

    }).catch(err => {
        Toast.fire({icon: "error", title: "Network error. Go to payments page to confirm payment."}).then(() => setOpenModal(false));
    })
  }

  //Modal Decline payment clicked
  const declinePayment = () => {
    const data = {salesID: paymentDetails.salesID, salesRef: paymentDetails.salesRef, status: "Declined"};
    Payment.updatePaymentStatus(data).then(res => {
        if(res.error) {
            return Toast.fire({icon: "error", title: res.message});
        }
        Toast.fire({icon: "success", title: "Payment has been declined."}).then(() => setOpenModal(false));
    }).catch(err => {
        Toast.fire({icon: "error", title: "Network error. Go to payments page to decline payment."}).then(() => setOpenModal(false));
    })
  }

  //Mark All Notifications as read
  const markAllNotificationsRead = e => {
    e.preventDefault();
    Notification.markNotificationsRead().then((res) => {
        Notification.allNotification().then(res => {
            res.notifications && setNotifications(res.notifications);
        })
    })
  }

  //Mark one notification as read
  const markOneNotificationsRead = (notificationID) => {
    
    Notification.markOneNotificationRead({notificationID}).then((res) => {
        console.log(res);
        Notification.allNotification().then(res => {
            res.notifications && setNotifications(res.notifications);
        })
    }).catch(err => {
        console.log();
    })
  }

    return (
        <div className="header" style={{backgroundColor: "#4281A4"}}>
			<div className="header-left">
				<a href="/" className="logo">
					<img src={logo} width="70" height="60" alt="" /> <span>Soft Collections</span>
				</a>
			</div>
			<a id="toggle_btn" href="#"><i className="fa fa-bars"></i></a>

            <a id="mobile_btn" className="mobile_btn float-left" href="#sidebar" ><i className="fa fa-bars"></i></a>

            <ul className="nav user-menu float-right">
                <li className="nav-item dropdown d-none d-sm-block">
                    <a href="#" className="dropdown-toggle nav-link" data-toggle="dropdown"><i className="fa fa-bell-o"></i> 
                        {
                            notifications.filter(noti => noti.read_status == 0).length > 0 &&
                            <span className="badge badge-pill bg-danger float-right">
                            {
                                notifications.filter(noti => noti.read_status == 0).length
                            }
                            </span>
                        }
                    </a>
                    <div className="dropdown-menu notifications">
                        <div className="topnav-dropdown-header">
                            <span>Notifications</span>
                        </div>
                        <div className="drop-scroll">
                            {
                                (notifications && notifications.length > 0) &&
                                <ul className="notification-list">
                                    {
                                        notifications.map(notification => {
                                            return (
                                            <li className="notification-message" key={notification.id}>
                                                <Link to={notification.url} onClick={() => markOneNotificationsRead(notification.id)}>
                                                    <div className="media">
                                                        <span className="avatar">
                                                            <img alt="John Doe" src={user} className="img-fluid" />
                                                        </span>
                                                        <div className="media-body" style={{fontWeight: notification.read_status ? "normal" : "bold"}}>
                                                            <p className="noti-details">{notification.info}</p>
                                                            <p className="noti-time"><span className="notification-time">{new Date(notification.date).toDateString() } | {new Date(notification.date).toLocaleTimeString() }</span></p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                            )
                                        })
                                    }
                                </ul>
                            }
                        </div>
                        <div className="topnav-dropdown-footer">
                            <a href="#" onClick={markAllNotificationsRead}>Mark all as read</a>
                        </div>
                    </div>
                </li>
                {/* <li className="nav-item dropdown d-none d-sm-block">
                    <a href="#" id="open_msg_box" className="hasnotifications nav-link"><i className="fa fa-comment-o"></i> <span className="badge badge-pill bg-danger float-right">8</span></a>
                </li> */}
                <li className="nav-item dropdown has-arrow">
                    <a href="#" className="dropdown-toggle nav-link user-link" data-toggle="dropdown">
                        <span className="user-img">
							<img className="rounded-circle" src={user} width="24" alt="Admin" />
						</span>
						<span>Admin</span>
                    </a>
					<div className="dropdown-menu">
						<Link className="dropdown-item" to="/myprofile">My Profile</Link>
						{/* <a className="dropdown-item" href="edit-profile.html">Edit Profile</a>
						<a className="dropdown-item" href="settings.html">Settings</a> */}
						<a className="dropdown-item" href="#" onClick={logout}>Logout</a>
					</div>
                </li>
            </ul>
            <div className="dropdown mobile-user-menu float-right">
                <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
                <div className="dropdown-menu dropdown-menu-right">
						<Link className="dropdown-item" to="/myprofile">My Profile</Link>
						<a className="dropdown-item" href="#" onClick={logout}>Logout</a>
                </div>
            </div>

            <ReactModal isOpen={openModal} style={customStyles} contentLabel="Example Modal" >
                <h3>Hello, Oga Soft!</h3>
                <p>Please confirm that you recieved this payment:</p>
                <p>
                    <b>Name:</b> {paymentDetails.payerName} <br />
                    <b>Bank:</b> {paymentDetails.payerBank} <br />
                    <b>Amount:</b> {paymentDetails.amount.toLocaleString()} <br />
                    <b>Amount in words:</b> {paymentDetails.amountWords} <br />
                    <b>Payment Date and Time:</b> {new Date(paymentDetails.paymentDate).toDateString() + " " + new Date(paymentDetails.paymentDate).toLocaleTimeString() } {} <br />
                </p>
                <button className="btn btn-primary" onClick={cofirmPayment}><i className="fa fa-check"></i> Yes, Confirmed</button>
                &nbsp; &nbsp;
                <button className="btn btn-danger" onClick={declinePayment}><i className="fa fa-cross"></i> No, decline</button>
            </ReactModal>

            <audio src={notificationSound} typeof="audio/mp3" ref={audioRef}></audio>
        </div>
    )
}

export default TopNav;