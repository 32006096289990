
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import user from "../../assets/img/user.jpg";
import { useState } from "react";
import { useEffect } from "react";
import { Sale } from "../../api";
import Toast from "../../components/Toast";
import io from "socket.io-client";
import Swal from "sweetalert2";
import Loader from "../../components/Loader";
import { serverurl } from "../../api";

const endpoint = "https://boms-api.softcollections.store";
let socket;

const Sales = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [sales, setSales] = useState([]);
    const [totalSales, setTotalSales] = useState([]);
    const [filterDate, setFilterDate] = useState({from: "", to: ""});

    useEffect(() => {
        // socket = io(endpoint);

        Sale.allSales().then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon:"error", title: res.message}).then(() => {
                    navigate("/");
                })
            }
            setTotalSales(res.sales);
            setSales(res.sales);
        }).catch(() => {
            setLoading(false);
            Toast.fire({icon:"error", title: "Network error"}).then(() => {
                navigate("/")
            })
        })

        // socket.on("update_sales_records", (data) => {
        //     Sale.allSales().then(res => {
        //         if (res.sales) {
        //             setTotalSales(res.sales);
        //             setSales(res.sales);
        //         }
        //     })
        // })

    }, [])

    const dateChange = e => {
        setFilterDate({...filterDate, [e.target.name]: e.target.value});
    }

    const filterSales = (e) => {
        e.preventDefault();
        if(filterDate.from && filterDate.to) {
            const sales = totalSales.filter(sale => {
                return (
                    new Date(sale.date_recorded.toString().slice(0,10)).getTime() >= new Date(filterDate.from).getTime() && new Date(sale.date_recorded.toString().slice(0,10)).getTime() <= new Date(filterDate.to).getTime()
                )
            })
            setSales(sales);
        }
    }


    // Delete Sale
    const deleteSale = ({id, saleid, customerid, staffid }) => {
        Swal.fire({
            title: `Are you sure?`,
            text: `Are you sure you want to delete this Sale record? The sales record and its payment record will be permanently removed.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "red",
            showConfirmButton: true,
            cancelButtonText: "Ohh No",
            confirmButtonText: "Yes, delete permanently"
        }).then(resp => {
            if (resp.isConfirmed) {
                Sale.deleteSale({saleid, customerid, staffid, id}).then(res => {
                    if (res.error) {
                        return Toast.fire({icon: "error", title: res.message});
                    }
                    
                    Toast.fire({icon: "success", title: "Sale record deleted successfully"}).then(() => {
                        Sale.allSales({staffID: Cookies.get("sc_staff")}).then(res => {
                            res.sales && setSales(res.sales);
                        })
                    });
                }).catch(err => {
                    Toast.fire({icon: "error", title: "Network error. Go back a step and retry"});
                })
            }
        })
    }

    return  (
        <BodyWrapper page={"sale"}>
            
            {
                loading && <Loader data={"Sales"} />
            }

            <div class="row">
                <div class="col-sm-4 col-3">
                    <h4 class="page-title">Sales</h4>
                </div>
                <div className="col-sm-8 col-9 text-right m-b-20">
                    <Link to="/record-sale" className="btn btn btn-primary btn-rounded float-right" style={{ color: "#222"}}><i className="fa fa-plus"></i> Record Sale</Link>
                    
                </div>
                <div class="col-sm-8 col-9 text-right m-b-20">
                    {/* <Link to="/record-sale" class="btn btn btn-primary btn-rounded float-right" style={{backgroundColor: "#c4e00a", color: "black"}}><i class="fa fa-plus"></i> Record Sales</Link> */}
                </div>
            </div>
        
            <div class="row filter-row">
                <div class="col-sm-6 col-md-3">
                    <div class="form-group form-focus">
                        <label class="focus-label">From</label>
                        <input className="form-control floating" type="date" name="from" value={filterDate.from} onChange={dateChange} />
                    </div>
                </div>
                <div class="col-sm-6 col-md-3">
                    <div class="form-group form-focus">
                        <label class="focus-label">To</label>
                        <input className="form-control floating" type="date" name="to" value={filterDate.to} onChange={dateChange} />
                    </div>
                </div>
                <div class="col-sm-6 col-md-3">
                    <a href="#" class="btn btn-success btn-block" onClick={filterSales}> Filter </a>
                </div>
            </div>

            <div className="row" style={{margin: "15px 0px"}}>
                <div className="col-md-6">
                    <h4>Total Sales: {sales.length.toLocaleString("en-US").padStart(3, "0")}</h4>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="table-responsive">
                        <table class="table table-striped custom-table">
                            <thead>
                                <tr>
                                    <th>Sales Ref</th>
                                    <th>Client</th>
                                    <th>Total Amount</th>
                                    <th>Products Purchased</th>
                                    {/* <th>Sales Rep</th> */}
                                    <th>Sales Date/Time</th>
                                    {/* <th>Sales Time</th> */}
                                    {/* <th>Status</th> */}
                                    <th class="text-center">Action</th>
                                </tr>
                            </thead>
                            {
                            (sales && sales.length > 0) &&
                            <tbody>
                                {
                                    sales.sort((a, b) => new Date(b.date_recorded).getTime() - new Date(a.date_recorded).getTime()).map(sale => {
                                        return (
                                        <tr>
                                            <td>{sale.salesid}</td>
                                            <td><Link to={"/customers/" + sale.customerid}> {sale.name} </Link></td>
                                            <td style={{textAlign: "center"}}>&#8358;{sale.total_amount?.toLocaleString()}</td>
                                            <td style={{textAlign: "center"}}>{sale.quantity}</td>
                                            <td>{new Date(sale.date_recorded).toDateString()} / {new Date(sale.date_recorded).toLocaleTimeString()}</td>
                                            {/* <td>
                                                {
                                                sale.purchase_status === "Completed" ?
                                                <span class="custom-badge status-green">{sale.purchase_status}</span>
                                                :
                                                sale.purchase_status === "Ongoing" ?
                                                <span class="custom-badge status-purple">{sale.purchase_status}</span>
                                                :
                                                <span class="custom-badge status-red">{sale.purchase_status}</span>
                                                }
                                            </td>  */}
                                            <td class="text-right">
                                                <div className="dropdown dropdown-action">
                                                    <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <Link className="dropdown-item" to={`/sales/${sale.id}?sid=${sale.salesid}`}><i className="fa fa-eye m-r-5"></i> View Details</Link>
                                                        <a 
                                                        className="dropdown-item" 
                                                        href="#" 
                                                        onClick={() => deleteSale({id: sale.id, saleid: sale.salesid, staffid: sale.salesrep_id, customerid: sale.customerid})}
                                                        >
                                                            <i className="fa fa-trash m-r-5"></i> Delete Sale
                                                        </a>
                                                    </div>
                                                </div>
                                                {/* <Link to={"/sales/" + sale.id} class="btn btn btn-primary" style={{backgroundColor: "#c4e00a", color: "black"}}>View Details</Link> */}
                                            </td>
                                        </tr>
                                        ) 
                                
                                    })
                                }
                                {/* <tr>
                                    <td>APT0002</td>
                                    <td><img width="28" height="28" src={user} class="rounded-circle m-r-5" alt="" /> Denise Stevens</td>
                                    <td style={{textAlign: "center"}}>35</td>
                                    <td>Henry Daniels</td>
                                    <td>30 Dec 2018</td>
                                    <td>10:00am - 11:00am</td>
                                    <td><span class="custom-badge status-green">Completed</span></td>
                                    <td class="text-right"><a href="#" class="btn btn btn-primary" style={{backgroundColor: "#c4e00a", color: "black"}}>View Details</a></td>
                                </tr> */}
                            </tbody>

                            }
                        </table>
                    </div>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default Sales;