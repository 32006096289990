
import './App.css';
import "./assets/css/bootstrap.min.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/dataTables.bootstrap4.min.css";
import "./assets/css/bootstrap-datetimepicker.min.css";
import "./assets/css/select2.min.css";
import "./assets/css/style.css";

import {BrowserRouter, Route, Routes} from "react-router-dom";

import Dashboard from './pages/Dashboard';
import Products from './pages/Product/Products';
import Sales from './pages/Sale/Sales';
import Expenses from './pages/Expense/Expenses';
import ProductProfile from './pages/Product/ProductDetails';
import Login from './pages/Login';
import SalesDetails from './pages/Sale/SalesDetails';
import RecordSale from './pages/Sale/RecordSale';
import EditProductProfile from './pages/Product/EditProduct';
import AddProduct from './pages/Product/AddProduct';

import AddExpense from './pages/Expense/AddExpense';

import ForgetPassword from './pages/ForgetPassword';
import MyProfile from './pages/Staff/MyProfile';
import EditMyProfile from './pages/Staff/EditMyProfile';

import ProductCategories from './pages/Product/Categories';

import Customers from './pages/Customer/Customers';
import AddCustomer from './pages/Customer/AddCustomer';
import CustomerProfile from './pages/Customer/CustomerProfile';
import EditCustomerProfile from './pages/Customer/EditCustomer';
import SalesReports from './pages/Sale/SalesReport';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Dashboard />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/login' element={<Login />} />
        <Route path='/forgot-password' element={<ForgetPassword />} />
        <Route path='/myprofile' element={<MyProfile />} />
        <Route path='/myprofile/edit' element={<EditMyProfile />} />

        <Route path='/products' element={<Products />} />
        <Route path='/products/categories' element={<ProductCategories />} />
        <Route path='/products/:id' element={<ProductProfile />} />
        <Route path='/products/:id/edit' element={<EditProductProfile />} />
        <Route path='/add-product' element={<AddProduct />} />

        <Route path='/sales' element={<Sales />} />
        <Route path='/sales/:id' element={<SalesDetails />} />
        <Route path='/record-sale' element={<RecordSale />} />
        <Route path='/sales-report' element={<SalesReports />} />

        <Route path='/expenses' element={<Expenses />} />
        <Route path='/record-expense' element={<AddExpense />} />
        
        <Route path='/customers' element={<Customers />} />
        <Route path='/add-customer' element={<AddCustomer />} />
        <Route path='/customers/:id' element={<CustomerProfile />} />
        <Route path='/customers/:id/edit' element={<EditCustomerProfile />} />
        

      </Routes>
    </BrowserRouter>
  );
}

export default App;
