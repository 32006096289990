import { Link } from "react-router-dom";
const SideNav = ({activePage}) => {
    return (
        
        <div className="sidebar" id="sidebar">
            <div className="sidebar-inner slimscroll">
                <div id="sidebar-menu" className="sidebar-menu">
                    <ul>
                        <li className="menu-title"></li>
                        <li className={(activePage && activePage == "dashboard") ? "active" : null}>
                            <Link to="/"><i className="fa fa-dashboard"></i> <span>Dashboard</span></Link>
                        </li>
                        <li className={(activePage && activePage == "product") ? "active" : null}>
                            <Link to="/products"><i className="fa fa-product-hunt"></i> <span>Products</span></Link>
                        </li>
                        <li className={(activePage && activePage == "sale") ? "active" : null}>
                            <Link to="/sales"><i className="fa fa-handshake-o"></i> <span>Sales</span></Link>
                        </li>
                        <li className={(activePage && activePage == "report") ? "active" : null}>
                            <Link to="/sales-report"><i className="fa fa-calendar"></i> <span>Sales Report</span></Link>
                        </li>
                        <li className={(activePage && activePage == "customer") ? "active" : null}>
                            <Link to="/customers"><i className="fa fa-users"></i> <span>Customers</span></Link>
                        </li>
                        <li className={(activePage && activePage == "expense") ? "active" : null}>
                            <Link to="/expenses"><i className="fa fa-money"></i> <span>Expenses</span></Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SideNav;
