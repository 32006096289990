
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Expense } from "../../api";
import Toast from "../../components/Toast";
import { useEffect } from "react";
import Swal from "sweetalert2";
import Loader from "../../components/Loader";
import { serverurl } from "../../api";

const Expenses = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [expenses, setExpenses] = useState([]);
    const [totalExpenses, setTotalExpenses] = useState([]);
    const [filterDate, setFilterDate] = useState({from: "", to: ""});

    useEffect(() => {
        Expense.allExpenses().then(res => {
            setLoading(false);
            if (res.error) {
                return Toast.fire({icon: "error", title: res.message});
            }
            setExpenses(res.expenses);
            setTotalExpenses(res.expenses);
        }).catch(() => {
            setLoading(false);
            Toast.fire({icon: "error", title: "Network error while loading expenses. Go back a step and retry."}).then(() => {
                navigate("/");
            });
        })
    }, []);

    const dateChange = e => {
        setFilterDate({...filterDate, [e.target.name]: e.target.value});
    }

    const filterExpenses = (e) => {
        e.preventDefault();
        if(filterDate.from && filterDate.to) {
            const expenses = totalExpenses.filter(expense => {
                const expenseDate = new Date(expense.date_added.toString().slice(0,10)).getTime();
                return (
                    (expenseDate >= new Date(filterDate.from).getTime() && expenseDate <= new Date(filterDate.to).getTime())
                )
            })
            setExpenses(expenses);
        }
    }

    
    const deleteExpense = ({id}) => {
        Swal.fire({
            title: `Are you sure?`,
            text: `Are you sure you want to delete this Expense record? It will be permanently removed.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "red",
            showConfirmButton: true,
            cancelButtonText: "Ohh No",
            confirmButtonText: "Yes, delete permanently"
        }).then(resp => {
            if (resp.isConfirmed) {
                Expense.deleteExpense({id}).then(res => {
                    if (res.error) {
                        return Toast.fire({icon: "error", title: res.message});
                    }
                    
                    Expense.allExpenses().then(res => {
                        if (res.expenses) {
                            setExpenses(res.expenses);
                            setTotalExpenses(res.expenses);
                        }
                    })
                }).catch(err => {
                    Toast.fire({icon: "error", title: "Network error. Go back a step and retry"});
                })
            }
        })
    }

    return  (
        <BodyWrapper page={"expense"}>
            {
                loading && <Loader data={"Expenses"} />
            }
            <div class="row">
                <div class="col-sm-4 col-3">
                    <h4 class="page-title">Expenses</h4>
                </div>
                <div class="col-sm-8 col-9 text-right m-b-20">
                    <Link to="/record-expense" class="btn btn btn-primary btn-rounded float-right" style={{ color: "black"}}><i class="fa fa-plus"></i> Add Expense</Link>
                </div>
            </div>
        
            <div class="row filter-row">
                <div class="col-sm-6 col-md-3">
                    <div class="form-group form-focus">
                        <label class="focus-label">From</label>
                        <input className="form-control floating" type="date" name="from" value={filterDate.from} onChange={dateChange} />
                    </div>
                </div>
                <div class="col-sm-6 col-md-3">
                    <div class="form-group form-focus">
                        <label class="focus-label">To</label>
                        <input className="form-control floating" type="date" name="to" value={filterDate.to} onChange={dateChange} />
                    </div>
                </div>
                <div class="col-sm-6 col-md-3">
                    <a href="#" class="btn btn-success btn-block" onClick={filterExpenses}> Filter </a>
                </div>
            </div>

            <div className="row" style={{margin: "15px 0px"}}>
                <div className="col-md-6">
                    <h4>Total Expenses: {expenses.length.toString().padStart(3, "0")}</h4>
                </div>
                <div className="col-md-6">
                    <h4>Total Amount: &#8358;{expenses.map(exp => exp.amount).length > 0 && expenses.map(exp => exp.amount).reduce((prev,total) => total + prev).toLocaleString()}</h4>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="table-responsive">
                        <table class="table table-striped custom-table">
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Amount</th>
                                    <th>Purchased From</th>
                                    <th>Payment Method</th>
                                    <th>Payment Date</th>
                                    <th class="text-center">Action</th>
                                </tr>
                            </thead>
                            {
                                (expenses && expenses.length > 0) &&
                                <tbody>
                                    {
                                        expenses.map((expense) => {
                                            return (
                                                <tr>
                                                    <td>{expense.item}</td>
                                                    <td>&#8358;{expense.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                                                    <td>{expense.purchased_from}</td>
                                                    <td>{expense.payment_method}</td>
                                                    <td>{new Date(expense.purchase_date).toDateString()}</td>
                                                    {/* <td><span class="custom-badge status-red">In Progress</span></td> */}
                                                    
                                                    {/* <td class="text-right">
                                                        <div className="dropdown dropdown-action">
                                                            <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v"></i></a>
                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                
                                                                {
                                                                    expense.receipt_url.length > 0 &&
                                                                <a href={serverurl + "/receipts/" + expense.receipt_url} className="dropdown-item" target="_blank" download><i className="fa fa-download"></i> Receipt</a>
                                                                }
                                                                
                                                                <a 
                                                                className="dropdown-item" 
                                                                href="#" 
                                                                onClick={() => deleteExpense({id: expense.id})}
                                                                >
                                                                    <i className="fa fa-trash m-r-5"></i> Delete Record
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td> */}

                                                    <td class="text-right">
                                                        {
                                                            expense.receipt_url.length > 0 &&
                                                        <a href={serverurl + "/receipts/" + expense.receipt_url} class="btn btn btn-primary" target="_blank" download><i className="fa fa-download"></i> Receipt</a>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            }
                        </table>
                    </div>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default Expenses;