
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useState } from "react";
import { useEffect } from "react";
import { Product } from "../../api";
import Toast from "../../components/Toast";
import Loader from "../../components/Loader";
import { serverurl } from "../../api";
import noImage from "../../assets/img/no-image.jpg"

const ProductDetails = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState({});
    const productID = useLocation().pathname.split("/")[2];

    useEffect(() => {
        Product.productDetails({productID}).then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message}).then(() => navigate("/products"));
            }
            console.log(res.product);
            setProduct(res.product);
        }).catch(err => {
            setLoading(false);
            Toast.fire({icon: "error", title: "Network error"}).then(() => navigate("/products"));
        })
    }, [])
    
    // const deleteProduct = () => {
    //     Swal.fire({
    //         title: `Are you sure?`,
    //         text: `Are you sure you want to delete ${product.name}? All its data including sales records and payment records will be permanently removed.`,
    //         icon: "warning",
    //         showCancelButton: true,
    //         confirmButtonColor: "red",
    //         showConfirmButton: true,
    //         cancelButtonText: "Ohh No",
    //         confirmButtonText: "Yes, delete permanently"
    //     }).then(res => {
    //         if (res.isConfirmed) {
    //             Product.deleteProduct({productID}).then(res => {
    //                 if(res.error) {
    //                     return Toast.fire({icon: "error", title: res.message})
    //                 }
    //                 Toast.fire({icon: "success", title: "Product data deleted successfully!"}).then(() => {
    //                     navigate("/products");
    //                 })
    //             }).catch(err => {
    //                 Toast.fire({icon: "error", title: "Network error. Go back a step and retry"});
    //             })
    //         }
    //     })
    // }

    return  (
        <BodyWrapper page={"product"}>
            
            {
                loading && <Loader data={"Product data"} />
            }

            <div className="row">
                <div className="col-12 text-left">
                    <Link to={"/products/"} className="btn btn-primiary "><i className="fa fa-arrow-left"></i> Back to Products</Link>
                </div>
                <div className="col-sm-7 col-6">
                    <h4 className="page-title">Product Information</h4>
                </div>

                <div className="col-sm-5 col-6 text-right m-b-30">
                    <Link to={"/products/" + productID + "/edit"} className="btn btn-primary btn-rounded"><i className="fa fa-plus"></i> Edit Product</Link> &nbsp;
                    {/* <button className="btn btn-danger btn-sm btn-rounded" onClick={deleteProduct}><i className="fa fa-trash"></i> Delete Product</button> */}
                </div>
            </div>
            <div className="card-box profile-header">
                <div className="row">
                    <div className="col-md-12">
                        {
                            product &&
                        <div className="profile-view">
                            <div className="profile-img-wrap">
                                <div className="profile-img">
                                    <a href="#"><img className="avatars" src={product.image_url ? serverurl + "/products_images/" + product.image_url : noImage} alt="" /></a>
                                </div>
                            </div>
                            <div className="profile-basic">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="profile-info-left">
                                            <h3 className="user-name m-t-0 mb-0">{product.name} </h3>
                                            <small className="text-muted">{product.occupation}</small>
                                            <div className="staff-id">product ID : PR-{product.id && product.id.toString().padStart(5, "0")}</div>
                                            {/* <div className="staff-msg">
                                                <a href={`whatsapp://send?text=${product.name} %0aPrice: ${product.price?.toLocaleString()} %0aManufacturer: ${product.manufacturer_name} %0aCountry: ${product.m_country}`} className="btn btn-primary"><i className="fa fa-whatsapp"></i> Share</a> &nbsp;
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <ul className="personal-info">
                                            <li>
                                                <span className="title">Quantity left:</span>
                                                <span className="text"><big>{product.quantity}</big></span>
                                            </li>
                                            <li>
                                                <span className="title">Selling Price:</span>
                                                <span className="text">&#8358;{product.price?.toLocaleString()}</span>
                                            </li>
                                            <li>
                                                <span className="title">Description:</span>
                                                <span className="text">{product.description || <i>No Description</i>}</span>
                                            </li>
                                            <li>
                                                <span className="title">Manufacturer:</span>
                                                <span className="text">{product.manufacturer_name}</span>
                                            </li>
                                            <li>
                                                <span className="title">Category:</span>
                                                <span className="text">{product.category || <i>Deleted</i>}</span>
                                            </li>
                                            <li>
                                                <span className="title">Date Added:</span>
                                                <span className="text">{new Date(product.date_added).toDateString()}</span>
                                            </li>
                                            <li>
                                                <span className="title">Total quantity sold:</span>
                                                <span className="text">{product.sales && product.sales.length > 0 ? product.sales.map(sale => sale.quantity).reduce((prev, val) => prev + val) : <i>No Purchase yet</i> }</span>
                                            </li>
                                            <li>
                                                <span className="title">Last Sale:</span>
                                                <span className="text">{product.sales && product.sales.length > 0 ? new Date(product.last_sale).toDateString() : <i>No Purchase yet</i>}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>  
                        }                      
                    </div>
                </div>
            </div>
            <div className="profile-tabs">
                <ul className="nav nav-tabs nav-tabs-bottom">
                    <li className="nav-item"><a className="nav-link active" href="#about-cont" data-toggle="tab">Purchases</a></li>
                </ul>

                <div className="tab-content">
                    <div className="tab-pane show active" id="about-cont">
                        <div className="row">
                            {
                                (product && product.sales) &&
                            <div className="col-md-12">
                                <div className="card-box">
                                    <h3 className="card-title">Purchases Records</h3>
                                    <div className="experience-box">
                                        {
                                            (product.sales && product.sales.length > 0) && ( 
                                            <ul className="experience-list">
                                                {
                                                    product.sales.sort((a, b) => new Date(b.date_recorded).getTime() - new Date(a.date_recorded).getTime()).map(sale => {
                                                        return (
                                                        <li>
                                                            <div className="experience-user">
                                                                <div className="before-circle"></div>
                                                            </div>
                                                            <div className="experience-content">
                                                                <div className="timeline-content">
                                                                    <Link to={`/sales/${sale.sale_id}?sid=${sale.salesid}`} className="name">#{sale.salesid}</Link>
                                                                    <div>&#8358;{sale.price && (sale.price * sale.quantity).toLocaleString(4, "0")}</div>
                                                                    <span className="time">{new Date(sale.date_recorded).toDateString()}</span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="tab-pane" id="bottom-tab2">
                        <div className="row">
                         
                        </div>
                    </div>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default ProductDetails;