
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Customer, Product, Sale, Staff } from "../../api";
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import Toast from "../../components/Toast";
import Loader from "../../components/Loader";

const RecordSale = () => {
    const navigate = useNavigate();
    
    const [form, setForm] = useState({client: "", item_types: 1, paymentMethod: "Transfer", payerBank: "", clientName: "", clientEmail: "", clientPhone: "", clientAddress: ""});

    const [allProducts, setAllProducts] = useState([]);
    const [products, setProducts] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [items, setItems] = useState([{product: "", quantity: 1, price: 0}]);
    const [pageLoading, setPageLoading] = useState(true);
    const [loading, setLoading] = useState(false);

    const addProduct = () => {
        setForm({ ...form, item_types: form.item_types+1 });
        items.push({product: "", quantity:1, price: 0});
        setItems(items);
        console.log(items);
    }

    const removeLastProduct = () => {
        items.pop();
        setItems(items);
        setForm({...form, item_types: form.item_types-1});
    }

    const inputChange = e => {
        setForm({...form, [e.target.name]: e.target.value });
    }

    const itemProductChange = e => {
        const name = e.target.name;
        const index = parseInt(name.slice(4));

        const selectedProduct = products.find(product => product.name == e.target.value );
        const productPicked = items.some(item => item.product == selectedProduct.id);
        // const productPicked = items.some(item => item.product == e.target.value);

        if (!productPicked) {
            items[index].product = selectedProduct.id;
            items[index].price = selectedProduct.price * items[index].quantity;
            setItems(items);
        }
    }

    const itemQuantityChange = (e) => {
        const name = e.target.name;
        const index = parseInt(name.slice(4));
        items[index].quantity = parseInt(e.target.value);
        items[index]["price"] = products.find(product => product.id == items[index].product).price * e.target.value;
        setItems(items);
    }

    const itemPriceChange = (e, index) => {
        const allItems = items;
        allItems[index].price = e.target.value.length > 2 ? parseInt(e.target.value.replace("₦", "").replace(",", "")) : e.target.value.replace("₦", "");
        setItems(allItems);
    }
    
    useEffect(()=> {
        let mounted = true;
        if (mounted) {
            Product.allProducts().then(res => {
                setPageLoading(false);
                if(res.error) {
                    return Toast.fire({icon: "error", title: res.message});
                }
                setAllProducts(res.products.filter(product => product.quantity > 0));
                setProducts(res.products.filter(product => product.quantity > 0));
            })

            Customer.allCustomers().then(res => {
                if(res.error) {
                    return Toast.fire({icon: "error", title: res.message});
                }
                setCustomers(res.customers);
            })
        }

        return () => {
            mounted = false;
        }
        // console.log(items);
    }, [items, products])

    const submitForm = e => {
        e.preventDefault();
        setLoading(true);
        
        const formData = {
            ...form,
            client: customers.find(c => c.name === form.client).id,
            items
        }
        
        Sale.recordSale(formData).then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message});
            }
            Toast.fire({icon: "success", title: "Sale recorded successfully!"}).then(() => navigate("/sales/"));
        }).catch(() => {
            setLoading(false);
            Toast.fire({icon: "error", title: "Network error. Check connection and retry."})
        })
    }

    return  (
        <BodyWrapper page={"sale"}>
            
            {
                pageLoading && <Loader data={"Data"} />
            }

        <div className="row">
            <div className="col-lg-8 offset-lg-2">
                <h4 className="page-title">Record Sale</h4>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-8 offset-lg-2">
                <form onSubmit={submitForm}>
                    <div className="row">
                        <div className="col-md-6">
                            <h4>Sale Details</h4>
                            <hr />
                        </div>
                    </div>
                        
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Client</label>
                                <input type="list" list="client" name="client" value={form.client} className="form-control" onChange={inputChange} />
                                <datalist id="client">
                                    <option value="">--Select Client--</option>
                                    <option value="new">++Add New Client++</option>
                                    {
                                        customers && customers.map(customer => {
                                            return <option value={customer.name}></option>
                                        })
                                    }
                                </datalist>
                            </div>
                        </div>
                    </div>
                    {
                        form.client === "new" && 
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Client Name <span className="text-danger">*</span></label>
                                    <input className="form-control" type="text" value={form.clientName} name="clientName" onChange={inputChange} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Client Phone <span className="text-danger">*</span></label>
                                    <input className="form-control" type="tel" value={form.clientPhone}  name="clientPhone" onChange={inputChange} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Client Email</label>
                                    <input className="form-control" type="email" value={form.clientEmail}  name="clientEmail" onChange={inputChange} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Client Address <span className="text-danger">*</span></label>
                                    <input className="form-control" type="text" value={form.clientAddress}  name="clientAddress" onChange={inputChange} />
                                </div>
                            </div>
                        </div>
                    }

                    {
                        items.map((val, index) => {
                            return (
                                <div className="row align-items-center">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Select Product {index + 1}</label>
                                            
                                            <input type="text" list={"item" + index} name={"item" + index} className="form-control" onChange={itemProductChange} />
                                            <datalist id={"item" + index}>
                                                <option value="">--Select Product--</option>
                                                {
                                                    products ? products.map(product => {
                                                        return <option value={product.name}></option>
                                                    })
                                                    :
                                                    <option value='id'>--No Product to load---</option>
                                                }
                                            </datalist>

                                            {/* <select name={"item" + index} id="" className="form-control" onChange={itemProductChange} value={items[index].product}>
                                                <option value="">--Select Product--</option>
                                                {
                                                    products && products.map(product => {
                                                        return <option value={product.id}>{product.name} -- {product.manufacturer_name}</option>
                                                    })
                                                }
                                            </select> */}
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Quantity</label>
                                            <input type="number" className="form-control" name={"item" + index} value={items[index].quantity} min="1" max={products.find(p => p.id == items[index].product)?.quantity} onChange={(e) => itemQuantityChange(e, index)} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Price</label>
                                            <input type="text" className="form-control" name="amount" value={"₦" + (items[index].price)} onChange={(e) => itemPriceChange(e, index)} />
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            )
                        })
                    }
                    <button type="button" className="btn btn-primary btn-sm" onClick={addProduct}><i className="fa fa-plus"></i> Add product</button> &nbsp;
                    {
                        items.length > 1 && <button type="button" className="btn btn-danger btn-sm" onClick={removeLastProduct}><i className="fa fa-trash"></i> Remove last</button>
                    }
                    
                    <br /><br /><br /><br />
                    
                    <div className="row">
                        <div className="col-md-6">
                            <h4>Payment Details</h4>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Total Amount</label>
                                <div className="cal-icon">
                                    <input type="text" className="form-control" readOnly value={"₦" + items.map(item => item.price).reduce((prev,val) => parseInt(prev) + parseInt(val))?.toLocaleString() } />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Payment Method</label>
                                <select className="form-control" name="paymentMethod" value={form.paymentMethod} onChange={inputChange}>
                                    <option value={"Transfer"}>Transfer</option>
                                    <option value={"Cash"}>Cash</option>
                                    <option value={"POS"}>POS</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Payer's Bank (For Transfer and POS)</label>
                                <input className="form-control" type="text" name="payerBank"  value={form.payerBank} onChange={inputChange} />
                            </div>
                        </div>
                    </div>
                    <div className="m-t-20 text-center">
                        <button className="btn btn-primary submit-btn">{loading ? "Recording Sale..." : "Record Sales"}</button>
                    </div>
                </form>
            </div>
        </div>
        </BodyWrapper>
    )
}

export default RecordSale;