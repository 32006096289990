
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { CSVLink, CSVDownload } from "react-csv";
import Loader from "../../components/Loader";

import user from "../../assets/img/user.jpg";
import { useState } from "react";
import { useEffect } from "react";
import { Customer, Staff } from "../../api";
import Toast from "../../components/Toast";
import UserInfoDropdown from "../../components/UserInfoDropdown";

const columns = [
    { name: 'Name', selector: row => row.name, sortable: true },
    { name: 'Address', selector: row => row.address, sortable: true },
    { name: 'Phone', selector: row => row.phone, sortable: true },
    { name: 'Email', selector: row => row.email, sortable: true },
    { name: 'Purchases', selector: row => row.purchases, sortable: true },
    { name: 'Last Purchase Date', selector: row => row.last_purchase, sortable: true },
    { name: 'Action', selector: row => row.action, },
];

const Customers = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [customerTypes, setCustomerTypes] = useState([]);
    const [customersData, setCustomersData] = useState([]);
    const [customerFilter, setCustomerFlter] = useState([]);

    const [filterData, setFilterData] = useState("name");
    const [filter, setFlter] = useState(false);

    useEffect(() => {
        Customer.allCustomers({}).then(res => {
            setLoading(false)
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message}).then(() => navigate("/"));
            }
            console.log(res);
            // setCustomers(res.customers);
            setCustomersData(res.customers.map(customer => ({
                ...customer, name: customer.name, 
                action: <Link className="btn btn-primary btn-sm" to={"/customers/" + customer.id}>Profile&gt;&gt;</Link>, 
                last_purchase: new Date(customer.last_purchase).toLocaleDateString()
            })));

            document.querySelectorAll(".rdt_TableCell").forEach(elem => {
                if(!elem.nextElementSibling) {
                    elem.classList.remove("bMgaAx");
                }
            })

        }).catch(err => {
            setLoading(false)
            console.log(err);
            Toast.fire({icon: "error", title: "Network error"}).then(() => navigate("/"));
        })

    }, [])

    const searchCustomer =e => {
        if(e.target.value.trim().length> 0) {
            setFlter(true);
            let results = [];
            if (filterData === "name") {
                results = customersData.filter(customer => customer.name.toLowerCase().indexOf(e.target.value.toLowerCase())> -1);
            } else {
                results = customersData.filter(customer => customer.phone.toString().indexOf(e.target.value.toLowerCase())> -1);
            }
            setCustomerFlter(results);
        } else {
            setFlter(false);
        }
    }

    const filterCustomerCategory = (e) => {
        if (e.target.value === "all") {
            return setFlter(false);
        }
        
        let categoryResults = [];
        categoryResults = customersData.filter(customer => customer.category == e.target.value);
        setFlter(true);
        setCustomerFlter(categoryResults);
    }

    return  (
        <BodyWrapper page={"customer"}>
            {
                loading && <Loader data={"Customers"} />
            }
            <div className="row">
                <div className="col-sm-4 col-3">
                    <h4 className="page-title">Customers</h4>
                </div>
                <div className="col-sm-8 col-9 text-right m-b-20">
                    <Link to="/add-customer" className="btn btn btn-primary btn-rounded float-right" style={{ color: "#222"}}><i className="fa fa-plus"></i> Add New Customer</Link>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-12 text-right m-b-1">
                    <div className="btn-group btn-group-sm">
                        {/* <button className="btn btn-white">CSV</button> */}
                        <CSVLink 
                        data={ 
                            filter ? 
                            customerFilter.map(customer => ({name: customer.name, email: customer.email, address: customer.address, phone_number: customer.phone, number_of_purchase: customer.purchases, last_purchase_date: customer.last_purchase}))
                            : 
                            customersData.map(customer => ({name: customer.name, email: customer.email, address: customer.address, phone_number: customer.phone, number_of_purchase: customer.purchases, last_purchase_date: customer.last_purchase}))
                        }  
                        className="btn btn-white"
                        >

                            <i className="fa fa-download fa-lg"></i> CSV Download
                        </CSVLink>
                    </div>
                </div>
            </div>
            <div className="row filter-row justify-content">
                <div className="col-sm-6 col-md-4">
                        <label className="focus-labelx">Search With</label>
                    <div className="form-group input-group">
                        <select className="form-control col-4" onChange={(e) => setFilterData(e.target.value)}>
                            <option value="name">Name</option>
                            <option value="phone">Phone</option>
                        </select>
                        <input type="text" className="form-control floatings" placeholder="Search customer.." onChange={searchCustomer} />
                    </div>
                    
                </div>
                
                {/* <div className="col-sm-6 col-md-4">
                    <label className="focus-label">Select customer Category</label>
                    <div className="form-group">
                        <select className="form-control " onChange={filterCustomerCategory}>
                            <option value="all">All Customers</option>
                            {
                                customerTypes.map(type => {
                                    return <option value={type.id}>{type.name}</option>
                                })
                            }
                        </select>
                    </div>
                </div> */}

            </div>

            {/* Data table  */}
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive">
                        {
                            filter ?
                            <DataTable columns={columns} data={customerFilter} />
                            :
                            <DataTable columns={columns} data={customersData} />
                        }
                    </div>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default Customers;