
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Product } from "../../api";
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import Toast from "../../components/Toast";
import Loader from "../../components/Loader";

const EditProductDetails = () => {
    const navigate = useNavigate();
    const [form, setForm] = useState({name: "", category: "", manufacturer: "", quantity: 0, description: "", price: "", manufacturer: "", category_id: "", cost_price: 0, cost_price_currency: ""});
    const [imageFile, setImageFile] = useState({});
    const [productTypes, setProductTypes] = useState([]);
    const [manufacturers, setManufacturers] = useState([]);

    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const inputChange = e => {
        setForm({...form, [e.target.name]: e.target.value });
    }

    const fileChange = e => {
        const uploadedFile = e.target.files[0];
        setImageFile(uploadedFile);
    }

    const productID = useLocation().pathname.split("/")[2];
    useEffect(() => {
        Product.productDetails({productID}).then(res => {
            setPageLoading(false);
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message}).then(() => navigate("/products"));
            }
            setForm(res.product);
        }).catch(err => {
            setPageLoading(false);
            Toast.fire({icon: "error", title: "Network error"}).then(() => navigate("/products"));
        })

        Product.getCategories().then(res => {
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message}).then(() => navigate("/"));
            }
            setProductTypes(res.categories);
        })

        Product.getManufacturers().then(res => {
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message}).then(() => navigate("/"));
            }
            setManufacturers(res.manufacturers);
        })

    }, [])

    const updateProduct = (e) => {
        e.preventDefault();
        setLoading(true);
        
        const productForm = new FormData();
        for (const key in form) {
            if (form.hasOwnProperty(key)) {
                productForm.append(key, form[key]);
            }
        }
        productForm.append("productID", productID)
        productForm.append("category_id", form.category_id)
        productForm.append("image", imageFile);

        Product.updateProduct(productForm).then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message})
            }
            Toast.fire({icon: "success", title: "Product details updated succesfully"}).then(() =>navigate("/products/" + productID));
        }).catch(err => {
            console.log(err);
            setLoading(false);
            Toast.fire({icon: "error", title: "Network error"});
        })
    }

    return  (
        <BodyWrapper page={"product"}>
            
            {
                pageLoading && <Loader data={"Product Information"} />
            }

            <div className="row">
                <div className="col-12 text-left">
                    <Link to={"/products/" + productID} className="btn btn-primiary "><i className="fa fa-arrow-left"></i> Back to profile</Link>
                </div>
                <div className="col-sm-12">
                    <h4 className="page-title">Update Product</h4>
                </div>
            </div>
            <form onSubmit={updateProduct}>
                <div className="card-box">
                    <h3 className="card-title">Basic Informations</h3>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="profile-basc">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group form-focus">
                                            <label className="focus-label">Product Name</label>
                                            <input type="text" className="form-control floating" name="name" value={form.name} onChange={inputChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group form-focus">
                                            <label className="focus-label">Product Price</label>
                                            <input type="text" className="form-control floating" name="price" value={form.price} onChange={inputChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group form-focus select-focus">
                                            <label className="focus-label">Quantity Left</label>
                                            <input type="text" className="form-control floating"  name="quantity" value={form.quantity} onChange={inputChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group form-focus select-focus">
                                            <label className="focus-label">Product Category</label>
                                            <select name="category_id" className="form-control floating" value={form.category_id} onChange={inputChange}>
                                                <option value="">--Select Category--</option>
                                                {
                                                    productTypes.map(type => {
                                                        return <option value={type.id}>{type.name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group form-focus select-focus">
                                            <label className="focus-label">Manufacturer</label>
                                            <select name="manufacturer" id="" className="form-control floating" value={form.manufacturer} onChange={inputChange}>
                                            <option value="">--Select Manufacturer--</option>
                                                {
                                                    manufacturers && manufacturers.map(manufacturer => {
                                                        return <option value={manufacturer.id}>{manufacturer.name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group form-focus select-focus">
                                            <label className="focus-label">Product Image</label>
                                            <input type="file" className="form-control floating"  name="imageFile" onChange={fileChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group form-focus select-focus">
                                            <label className="focus-label">Product Description</label>
                                            <input type="text" className="form-control floating"  name="description" value={form.description} onChange={inputChange} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    
                    <div className="text-center m-t-20">
                        <button className="btn btn-primary submit-btn" type="submit" style={{color: "#000"}}>{loading ? "Saving.." : "Save"}</button>
                    </div>
                </div>
                
            </form>
        </BodyWrapper>
    )
}

export default EditProductDetails;