
import BodyWrapper from "../components/Layouts/BodyWrapper";
import { Link, useNavigate } from "react-router-dom";
import StatChart from "../components/Chart";
import user from "../assets/img/user.jpg";
import { useState } from "react";
import { useEffect } from "react";
import { Staff } from "../api";
import Toast from "../components/Toast";
import Cookies from "js-cookie";
import { CustomerStatChart } from "../components/Chart";
import Loader from "../components/Loader";
import io from "socket.io-client";
import { serverurl as endpoint } from "../api";

let socket;

const monthsData = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
const staffRolesCodes = {sales_rep: "Sales Representative", receptionist: "Receptionist", admin: "Administrator"};

const Dashboard = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({sales: [], staffs: [], customers: [], recentSales: []});
    const [months, setMonths] = useState([]);
    const [monthlySales, setMonthlySales] = useState([]);
    const [currentSaleMonth, setCurrentSaleMonth] = useState([]);

    useEffect(() => {
        socket = io(endpoint);

        const token = Cookies.get("hv_staff");
        if(!token  || token.length < 1) {
            return navigate("/login");
        }

        
        const currentDate = {month: new Date().getMonth(), year: new Date().getFullYear()};
        const dec2022 = {month: new Date("20 December, 2022").getMonth(), year: new Date("20 December, 2022").getFullYear()};
        const monthsCount = ((currentDate.year - dec2022.year) * 12) - (dec2022.month - currentDate.month);

        const newMonths = [];
        for (let i = monthsCount; i >= 0; i--) {
            const currentMonth = (dec2022.month + i) % 12;
            const currentYear = Math.floor((dec2022.month + i) / 12) + (dec2022.year);
            const monthData = monthsData[currentMonth]  + ", " + currentYear;
            newMonths.push(monthData)
        }
        setMonths(newMonths);

        Staff.staffDashboard().then((res) => {
            setLoading(false);
            if(res.error){
                return Toast.fire({icon: "error", title: res.message}).then(() => {
                    navigate("/login")
                }) 
            }
            setData(res.dashboardDetails);
            setMonthlySales(res.dashboardDetails.sales)
        }).catch(err => {
            setLoading(false);
            Toast.fire({icon: "error", title: "Network error. Log out and relogin."}).then(() => {
                Cookies.remove("sc_staff");
                navigate("/login")
            })
        })

        // socket.on("update_sales_records", (data) => {
        //     Staff.adminDashboard().then((res) => {
        //         res.dashboardDetails && setData(res.dashboardDetails);
        //     })
        // })
        
    }, [])

    const getMonthlySales = (e) => {
        setCurrentSaleMonth(e.target.value);
        if (e.target.value !== "all") {
            Staff.monthlyData({date: e.target.value.replace(",", "")}).then(res => {
                if(res.error) {
                    return Toast.fire({icon: "error", title: res.message}).then(() => navigate("/login"));
                }
                setMonthlySales(res.sales);
            })
        } else {
            setMonthlySales(data.sales);
        }
    }

    return (
        <BodyWrapper page={"dashboard"}>
            
            {
                loading && <Loader data={"Staff Dashboard"} />
            }
            <div className="col-4"></div>
            <select name="" id="" value={currentSaleMonth} className="form-control col-md-2 mb-2" onChange={getMonthlySales}>
                <option value="all">All Time</option>
                {
                    months && months.reverse().map((month, i) => {
                        return i === 0 ? <option key={i} value={month}>This Month</option> : <option key={i} value={month}>{month}</option>
                    })
                }
            </select>
            <div className="row">
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                    <Link to={"/products"}>
                        <div className="dash-widget">
                            <span className="dash-widget-bg2"><i className="fa fa-users"></i></span>
                            <div className="dash-widget-info text-right">
                                <h3 style={{color: "#666"}}>{data.products?.length.toLocaleString("en-US").padStart(4, "0")}</h3>
                                <span className="widget-title2">Products <i className="fa fa-check" aria-hidden="true"></i></span>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                    <Link to={"/sales"}>
                        <div className="dash-widget">
                            <span className="dash-widget-bg3"><i className="fa fa-handshake-o" aria-hidden="true"></i></span>
                            <div className="dash-widget-info text-right">
                                <h3 style={{color: "#666"}}>{monthlySales.length.toLocaleString("en-US").padStart(4, "0")}</h3>
                                <span className="widget-title3">Sales <i className="fa fa-check" aria-hidden="true"></i></span>
                            </div>
                        </div>
                    </Link>
                </div>

            </div>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="chart-title">
                                <h4>Total Sales</h4>
                                {/* <span className="float-right"><i className="fa fa-caret-up" aria-hidden="true"></i> 15% Higher than Last Month</span> */}
                            </div>

                            {
                                (data.sales && data.sales.length > 0) && <StatChart sales={data.sales} />
                            }

                        </div>
                    </div>
                </div>
                
            </div>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-8 col-xl-8">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title d-inline-block">Recent Sales</h4> <Link to="/sales" className="btn btn-primary float-right">View all</Link>
                        </div>
                        <div className="card-body p-0">
                            <div className="table-responsive">
                                <table className="table mb-0">
                                    <thead className="d-none">
                                        <tr>
                                            <th>Client Name</th>
                                            <th>Product(s)</th>
                                            <th>Sales Time</th>
                                            <th className="text-right">Status</th>
                                        </tr>
                                    </thead>
                                    {
                                        data.recentSales.length > 0 && 
                                    <tbody>
                                        {
                                            data.recentSales.map((sale, i) => {
                                            // [...new Array(5)].map((sale, i) => {
                                                return (
                                                        
                                                    <tr key={i}>
                                                        <td style={{minWidth: "200px"}}>
                                                            <Link className="avatar" to={"/customers/" + sale.customerid}>{sale.c_name[0]}</Link>
                                                            <h2><Link to={"/customers/" + sale.customerid}>{sale.c_name}</Link></h2>
                                                        </td>                 
                                                        <td>
                                                            <h5 className="time-title p-0">Sale Date/Time</h5>
                                                            <p>{new Date(sale.date_recorded).toDateString()} / {new Date(sale.date_recorded).toLocaleTimeString()}</p>
                                                        </td>
                                                        <td>
                                                            <h5 className="time-title p-0">Amount</h5>
                                                            <p>&#8358; {sale.total_amount.toLocaleString()}</p>
                                                        </td>
                                                        <td className="text-right">
                                                            <Link to={`/sales/` + sale.id} className="btn btn-outline-primary take-btn btn-sm">View Details</Link>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 col-xl-4">
                    <div className="card member-panel">
                        <div className="card-header bg-white">
                            <h4 className="card-title mb-0">Products</h4>
                        </div>
                        <div className="card-body">
                            <ul className="contact-list">
                                {
                                    data.products?.map((product, i) => {
                                        if ( i < 5) 
                                        return (   
                                            <li>
                                                <div className="contact-cont">
                                                    <div className="float-left user-img m-r-10">
                                                        <Link to={"/products/" + product.id} title={product.name}><img src={endpoint + "/products_images/" + product.image_url} alt="" className="w-40"/><span className="statuss onlines"></span></Link>
                                                    </div>
                                                    <div className="contact-info">
                                                        <span className="contact-name text-ellipsis">{product.name}</span>
                                                        <span className="contact-date">{product.quantity} left</span>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className="card-footer text-center bg-white">
                            <Link to="/products" className="text-muted">View all products</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-9 col-xl-9">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title d-inline-block">New CLients </h4> <Link to="/customers" className="btn btn-primary float-right">View all</Link>
                        </div>
                        <div className="card-block">
                            <div className="table-responsive">
                                <table className="table mb-0 new-patient-table">
                                    <tbody>
                                        {
                                            data.customers.map((customer, i) => {
                                                return (
                                                    (i < 5) && (<tr>
                                                        <td>
                                                            <img width="28" height="28" className="rounded-circle" src={user} alt="" /> 
                                                            <h2>{customer.name}</h2>
                                                        </td>
                                                        <td>{customer.address}</td>
                                                        <td>{customer.phone}</td>
                                                        <td>
                                                            <Link to={"/customers/" + customer.id} className="btn btn-outline-primary take-btn float-right">View Profile</Link>
                                                        </td>
                                                    </tr>)
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default Dashboard;