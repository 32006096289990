
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Product } from "../../api";
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import Toast from "../../components/Toast";
import Loader from "../../components/Loader";

const AddProduct = () => {
    const navigate = useNavigate();
    const [form, setForm] = useState({name: "", category: "", manufacturer: "", quantity: 0, price: "", description: "", newCategory: false, newManufacturer: false, manufacturerCountry: "", cost: 0, priceCurrency: "Naira"});
    const [imageFile, setImageFile] = useState({});
    const [manufacturers, setManufacturers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const inputChange = e => {
        if (e.target.name === "newManufacturerData") {
            return setForm({...form, manufacturer: e.target.value});
        } else if (e.target.name === "newCategoryData") {
            return setForm({...form, category: e.target.value});
        }

        setForm({...form, [e.target.name]: e.target.value });
        if (e.target.name === "category" ) {
            e.target.value === "add_new_category" ? 
            setForm({...form, newCategory: true}) : setForm({...form, newCategory: false, category: e.target.value});
        } else if (e.target.name === "manufacturer") {
            e.target.value === "add_new_manufacturer" ? 
            setForm({...form, newManufacturer: true}) : setForm({...form, newManufacturer: false, manufacturer: e.target.value});
        }

    }

    const fileChange = e => {
        const uploadedFile = e.target.files[0];
        setImageFile(uploadedFile);
    }

    const addProduct = (e) => {
        e.preventDefault();
        setLoading(true);
        const productForm = new FormData();
        for (const key in form) {
            if (form.hasOwnProperty(key)) {
                productForm.append(key, form[key]);
            }
        }
        productForm.append("image", imageFile);
// console.log(form);
        Product.addProduct(productForm).then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message });
            }
            Toast.fire({icon: "success", title: res.message }).then(() => {
                navigate("/products/" + res.productID);
            });
        }).catch(err => {
            setLoading(false);
            Toast.fire({icon: "error", title: "Network error"});
        })
    }

    useEffect(() => {
        Product.getManufacturers().then(res => {
            setManufacturers(res.manufacturers);
        }).catch(() =>{
            Toast.fire({icon: "error", title: "Network error. Go back a step and reload" }).then(() => {
                navigate("/products");
            });
        })

        Product.getCategories().then(res => {
            setPageLoading(false);
            setCategories(res.categories);
        }).catch(() =>{
            setPageLoading(false);
            Toast.fire({icon: "error", title: "Network error. Go back a step and reload" }).then(() => {
                navigate("/products");
            });
        })
    })

    return  (
        <BodyWrapper page={"product"}>
            
            {
                pageLoading && <Loader data={"Credentials"} />
            }
            <div className="row">
                <div className="col-12 text-left">
                    <Link to={"/products/"} className="btn btn-primiary "><i className="fa fa-arrow-left"></i> Back to Products</Link>
                </div>
                <div className="col-lg-8 offset-lg-2">
                    <h4 className="page-title">Add Product</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <form onSubmit={addProduct}>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Product Name <span className="text-danger">*</span></label>
                                    <input className="form-control" name="name" type="text" onChange={inputChange} value={form.name} />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label>Product Category <span className="text-danger">*</span></label>
                                    <select className="form-control selects" name="category" onChange={inputChange}>
                                        <option value="">- Select -</option>
                                        {
                                            categories && categories.map(category => {
                                                return <option key={category.id} value={category.id}>{category.name}</option>
                                            })
                                        }
                                        <option value="add_new_category">Add New Category</option>
                                    </select>
                                </div>
                            </div>

                            {
                            form.newCategory &&
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Cateogry Name <span className="text-danger">*</span></label>
                                    <input className="form-control" name="newCategoryData" type="text" onChange={inputChange} />
                                </div>
                            </div>
                            }
                            
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-sm-6 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label>Quantity in Stock <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name="quantity" value={form.quantity}  onChange={inputChange}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label>Manufacturer <span className="text-danger">*</span></label>
                                            <select className="form-control selects" name="manufacturer" onChange={inputChange}>
                                                <option value="">- Select -</option>
                                                {
                                                    manufacturers && manufacturers.map(manufacturer => {
                                                        return <option key={manufacturer.id} value={manufacturer.id}>{manufacturer.name}</option>
                                                    })
                                                }
                                                <option value="add_new_manufacturer">Add New Manufacturer</option>
                                            </select>
                                        </div>
                                    </div>
                                    {
                                    form.newManufacturer &&
                                    <>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Product Manufacturer Name <span className="text-danger">*</span></label>
                                                <input className="form-control" name="newManufacturerData" type="text" onChange={inputChange}/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label>Product Manufacturer Country<span className="text-danger">*</span></label>
                                                <input className="form-control" name="manufacturerCountry" type="text" onChange={inputChange}/>
                                            </div>
                                        </div>
                                    </>
                                    }
                                </div>
                            </div>
                            {/* <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group ">
                                    <label>Product Cost Price <span className="text-danger">*</span></label>
                                    <input type="number" className="form-control" name="cost" value={form.cost} onChange={inputChange}/>
                                </div>
                            </div>
                                
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label>Product Cost Price Currency <span className="text-danger">*</span></label>
                                    <select className="form-control" name="priceCurrency" value={form.priceCurrency} onChange={inputChange}>
                                        <option value="Naira">Naira</option>
                                        <option value="Dollar">Dollar</option>
                                        <option value="Euro">Euro</option>
                                    </select>
                                </div>
                            </div> */}
                            <div className="col-sm-6 col-md-6 col-lg-6 form-group">
                                <label>Product Selling Price (in Naira) <span className="text-danger">*</span></label>
                                <input type="number" className="form-control" name="price" value={form.price} onChange={inputChange}/>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label>Product Image</label>
                                    <input type="file" className="form-control" name="image"  onChange={fileChange}/>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>Product Description</label>
                                    <input className="form-control" type="text" name="description" onChange={inputChange}/>
                                </div>
                            </div>
                        </div>
                        <div className="m-t-20 text-center">
                            <button className="btn btn-primary submit-btn">{loading ? "Adding product..." : "Add Product"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default AddProduct;