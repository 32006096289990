
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Customer } from "../../api";
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import Toast from "../../components/Toast";
import Loader from "../../components/Loader";

const AddCustomer = () => {
    const navigate = useNavigate();
    const [form, setForm] = useState({name: "", phone: "", email: "", address: "" });
    
    const [loading, setLoading] = useState(false);

    const inputChange = e => {
        setForm({...form, [e.target.name]: e.target.value });
    }

    const addCustomer = (e) => {
        e.preventDefault();
        setLoading(true);
        
        Customer.addCustomer(form).then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message });
            }
            Toast.fire({icon: "success", title: res.message }).then(() => {
                navigate("/customers/" + res.customerID);
            });
        }).catch(err => {
            setLoading(false);
            console.log(err);
            Toast.fire({icon: "error", title: "Network error"});
        })
    }


    return  (
        <BodyWrapper page={"customer"}>
            
            <div className="row">
                <div className="col-12 text-left">
                    <Link to={"/customers/"} className="btn btn-primiary "><i className="fa fa-arrow-left"></i> Back to Customers</Link>
                </div>
                <div className="col-lg-8 offset-lg-2">
                    <h4 className="page-title">Add Customer</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <form onSubmit={addCustomer}>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label> Name <span className="text-danger">*</span></label>
                                    <input className="form-control" name="name" type="text" onChange={inputChange} value={form.name} required />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label> Phone <span className="text-danger">*</span></label>
                                    <input className="form-control" name="phone" type="text" onChange={inputChange} value={form.phone} required />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label> Email <span className="text-danger"></span></label>
                                    <input className="form-control" name="email" type="text" onChange={inputChange} value={form.email} />
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label> Address <span className="text-danger">*</span></label>
                                    <input className="form-control" name="address" type="text" onChange={inputChange} value={form.address} required />
                                </div>
                            </div>
                            
                        </div>

                        <div className="m-t-20 text-center">
                            <button className="btn btn-primary submit-btn">{loading ? "Adding customer..." : "Add Customer"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default AddCustomer;