
import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Sale } from "../../api";
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import logo from "../../assets/img/horlamatlogo.png";
import Toast from "../../components/Toast";
import Loader from "../../components/Loader";

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

const SalesDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState(true);
    const [sale, setSale] = useState([]);
    
    const saleRef = location.pathname.split("/")[2];

    useEffect(() => {
        if (!saleRef) {
            Toast.fire({icon: "error", title: "Data error. Go back a step and reload."}).then(() => navigate("/sales") ) ;
        }


        Sale.saleDetails({saleID: saleRef}).then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message}).then(() => navigate("/sales") )
            }
            setSale(res.saleDetails);
        }).catch(err => {
            setLoading(false);
            Toast.fire({icon: "error", title: "Data error. Go back a step and reload."}).then(() => navigate("/sales"))
        })
    }, [])

    const Print = () => {
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
       document.body.innerHTML = originalContents; 
    }

    return  (
        <BodyWrapper page={"sale"}>
            
            {
                loading && <Loader data={"Sale Details"} />
            }

            <div class="row">
                    <div className="col-12 text-left mj-bj-30">
                        <Link to="/sales" className="btn btn-primiary "><i className="fa fa-arrow-left"></i> Back to Sales</Link>
                    </div>
                <div class="col-sm-5 col-4">
                    <h4 class="page-title">Sales Details</h4>
                </div>
                <div className="col-sm-7 col-8 text-right m-b-30">
                    <div className="btn-group btn-group-sm">
                        <button className="btn btn-white" onClick={Print}><i className="fa fa-print fa-lg"></i> Print</button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card" id="printablediv">
                        <div className="card-body">
                            <div className="row custom-invoice">
                                <div className="col-6 col-sm-6 m-b-20">
                                    <img src={logo} className="inv-logo" alt="Horlamat Logo" />
                                    <h4>HORLA-MAT VENTURES Nigeria Ltd.</h4>
                                    {/* <span>Nigeria Limited</span> */}
                                    <ul className="list-unstyled">
                                        <li></li>
                                        <li></li>
                                        <li>Blue Building, Opp International Conference Centre,</li>
                                        <li>U.I 2nd Gate, Ibadan, Oyo State</li>
                                        <li>Nigeria.</li>
                                        <li>GST No: +234(0) 803 455 2985</li>
                                    </ul>
                                </div>
                                <div className="col-6 col-sm-6 m-b-20">
                                    <div className="invoice-details">
                                        <h3 className="text-uppercase">#REF-{(sale[0]?.sale_id)?.toLocaleString().padStart(4, "0")}</h3>
                                        <ul className="list-unstyled">
                                            <li>Date: <span> {new Date(sale[0]?.date_recorded).toDateString().slice(8,15).replace(" ", " " + months[new Date(sale[0]?.date_recorded).getMonth()] + ", " )}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-lg-6 m-b-20">
                                    
                                        <h5>Reciept to:</h5>
                                        <ul className="list-unstyled">
                                            <li>
                                                <h5><strong>{sale[0]?.c_name}</strong></h5>
                                            </li>
                                            <li><span>{sale[0]?.address}</span></li>
                                            <li>{sale[0]?.phone}</li>
                                            <li>{sale[0]?.email}</li>
                                        </ul>
                                    
                                </div>
                                <div className="col-sm-6 col-lg-6 m-b-20">
                                    <div className="invoices-view">
                                        <span className="text-muted">Payment Details:</span>
                                        <ul className="list-unstyled invoice-payment-details">
                                            <li>
                                                <h5>Total Amount: <span className="text-right">&#8358;{(sale[0]?.total_amount)?.toLocaleString()}</span></h5>
                                            </li>
                                            <li>Payer Name: <span>{ sale[0] && sale[0].c_name}</span></li>
                                            <li>Payment Method: <span>{sale[0]?.payment_method}</span></li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>ITEM(S)</th>
                                            <th>COST</th>
                                            <th>QUANTITY</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            sale && sale.map(sale_item => {
                                                return (
                                                    <tr>
                                                        <td></td>
                                                        <td>{sale_item.p_name}</td>
                                                        <td>&#8358;{sale_item.price.toLocaleString()}</td>
                                                        <td>{sale_item.quantity}</td>
                                                        <td>&#8358;{(sale_item.price * sale_item.quantity).toLocaleString()}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <div className="row invoice-payment">
                                    <div className="col-sm-7">
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="m-b-20">
                                            <h6>Total paid</h6>
                                            <div className="table-responsive no-border">
                                                <table className="table mb-0">
                                                    <tbody>
                                                        <tr>
                                                            <th>Subtotal:</th>
                                                            <td className="text-right">&#8358;{(sale[0]?.total_amount)?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Tax: <span className="text-regular">(0%)</span></th>
                                                            <td className="text-right">&#8358;0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Total:</th>
                                                            <td className="text-right text-primary">
                                                                <h5>&#8358;{(sale[0]?.total_amount)?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h5>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div><br /><br /><br />
                                <div className="invoice-info">
                                    <h5>Terms and Conditions</h5>
                                    <p className="text-muted">All goods purchased in good condition cannot be returned faulty. - HORLAMAT VENTURES Nigeria Limited</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default SalesDetails;