
const getDateInterval = (period) => {
    let interval = {from: "", to: ""};

    const today = new Date();
    switch (period) {
        case "today":
            interval = {from: today, to: today};
            break;
        case "yesterday":
            interval = { 
                from: new Date(today.getTime() - 24*60*60*1000), 
                to: new Date(today.getTime() - 24*60*60*1000)
            };
            break;
        case "thisweek":
            interval = {
                from: new Date(new Date().setDate(today.getDate() - today.getDay())),
                to: today
            };
            break;
        case "lastweek":
            interval = {
                from: new Date(new Date().setDate(today.getDate() - today.getDay() - 7)),
                to: new Date(new Date().setDate(today.getDate() - new Date().getDay() - 1))
            };
            break;
        case "thismonth":
            interval = {
                from: new Date(today.getFullYear(), today.getMonth(), 1),
                to: today
            };
            break;
        case "lastmonth":
            interval = {
                from: new Date(today.getFullYear(), today.getMonth() - 1,1),
                to: new Date(today.getFullYear(), today.getMonth(), 0)
            };
            break;

        default:
            break;
    }

    return interval;

}

export default getDateInterval;