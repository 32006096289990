
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Customer } from "../../api";
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import Toast from "../../components/Toast";

const EditCustomerProfile = () => {
    const navigate = useNavigate();
    const [form, setForm] = useState({name: "", phone: "", email: "", gender: "", address: "" });
    const [loading, setLoading] = useState(false);

    const inputChange = e => {
        setForm({...form, [e.target.name]: e.target.value });
    }

    const customerID = useLocation().pathname.split("/")[2];
    useEffect(() => {
        Customer.customerProfile({customerID}).then(res => {
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message}).then(() => navigate("/customers"));
            }
            setForm(res.customer);
        }).catch(err => {
            console.log(err)
            Toast.fire({icon: "error", title: "Network error"}).then(() => navigate("/customers"));
        })

    }, [])

    const updateCustomer = (e) => {
        e.preventDefault();
        setLoading(true);
        Customer.updateCustomer({...form, customerID}).then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message})
            }
            Toast.fire({icon: "success", title: "Customer account updated succesfully"}).then(() =>navigate("/customers/" + customerID));
        }).catch(err => {
            setLoading(false);
            Toast.fire({icon: "error", title: "Network error"});
        })
    }

    return  (
        <BodyWrapper page={"customer"}>
            
            <div className="row">
                <div className="col-12 text-left">
                    <Link to={"/customers/" + customerID} className="btn btn-primiary "><i className="fa fa-arrow-left"></i> Back to profile</Link>
                </div>
                <div className="col-sm-12">
                    <h4 className="page-title">Update Customer Profile</h4>
                </div>
            </div>
            <form onSubmit={updateCustomer}>
                <div className="card-box">
                    <h3 className="card-title">Basic Informations</h3>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="profile-basc">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group form-focus">
                                            <label className="focus-label">Name</label>
                                            <input type="text" className="form-control floating" name="name" value={form.name} onChange={inputChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group form-focus">
                                            <label className="focus-label">Phone</label>
                                            <input type="tel" className="form-control floating" name="phone" value={form.phone} onChange={inputChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group form-focus">
                                            <label className="focus-label">Email</label>
                                            <input type="email" className="form-control floating" name="email" value={form.email} onChange={inputChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group form-focus">
                                            <label className="focus-label">Address</label>
                                            <input type="text" className="form-control floating" name="address" value={form.address} onChange={inputChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center m-t-20">
                    <button className="btn btn-primary submit-btn" type="submit" style={{color: "#000"}}>{loading ? "Saving.." : "Save"}</button>
                </div>
            </form>
        </BodyWrapper>
    )
}

export default EditCustomerProfile;