
import logo from "../assets/img/horlamatlogo.png";
import { Link } from "react-router-dom";
import { useState } from "react";

const ForgetPassword = () => {

    const [form, setForm] = useState({email: "", password: ""});

    const inputChange = e => {
        setForm({...form, [e.target.name]: e.target.value });
    }

    return  (
    <div class="main-wrapper account-wrapper">
        <br /> <br /><br />
    <div class="account-page">
        <div class="account-center">
            <div class="account-box">
                <form action="http://dreamguys.co.in/preclinic/template/index.html" class="form-signin">
                    <div class="account-logo">
                        <img src={logo} alt="" />
                    </div>
                    <div class="form-group">
                        <label>Email</label>
                        <input type="text" autofocus="" class="form-control" onChange={inputChange} value={form.email} />
                    </div>
                    <div class="form-group text-center">
                        <button type="submit" class="btn btn-primary account-btn">Reset Password</button>
                    </div>
                    <div class="form-group text-center">
                        <Link to="/login">Back to login</Link>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
    )
}

export default ForgetPassword;