
import BodyWrapper from "../../components/Layouts/BodyWrapper";
import { Link, useLocation, useNavigate } from "react-router-dom";

import user from "../../assets/img/user.jpg";
import { useState } from "react";
import { useEffect } from "react";
import { Customer } from "../../api";
import Toast from "../../components/Toast";
import Swal from "sweetalert2";
import Loader from "../../components/Loader";
import { serverurl } from "../../api";

const CustomerProfile = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [customer, setCustomer] = useState({});
    const customerID = useLocation().pathname.split("/")[2];

    useEffect(() => {
        Customer.customerProfile({customerID}).then(res => {
            setLoading(false);
            if(res.error) {
                return Toast.fire({icon: "error", title: res.message}).then(() => navigate("/customers"));
            }
            console.log(res.customer);
            setCustomer(res.customer);
        }).catch(err => {
            console.log(err);
            setLoading(false);
            Toast.fire({icon: "error", title: "Network error"}).then(() => navigate("/customers"));
        })
    }, [])
    
    const deleteCustomer = () => {
        Swal.fire({
            title: `Are you sure?`,
            text: `Are you sure you want to delete ${customer.name}? All its data including sales records and payment records will be permanently removed.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "red",
            showConfirmButton: true,
            cancelButtonText: "Ohh No",
            confirmButtonText: "Yes, delete permanently"
        }).then(res => {
            console.log(res);
            if (res.isConfirmed) {
                Customer.deleteCustomer({customerID}).then(res => {
                    if(res.error) {
                        return Toast.fire({icon: "error", title: res.message})
                    }

                    Toast.fire({icon: "success", title: "Customer data deleted successfully!"}).then(() => {
                        navigate("/customers");
                    })
                }).catch(err => {
                    Toast.fire({icon: "error", title: "Network error. Go back a step and retry"});
                })
            }
        })
    }

    return  (
        <BodyWrapper page={"customer"}>
            
            {
                loading && <Loader data={"Customer data"} />
            }

            <div className="row">
                <div className="col-12 text-left">
                    <Link to={"/customers/"} className="btn btn-primiary "><i className="fa fa-arrow-left"></i> Back to Customers</Link>
                </div>
                <div className="col-sm-7 col-6">
                    <h4 className="page-title">Customer Information</h4>
                </div>

                <div className="col-sm-5 col-6 text-right m-b-30">
                    <Link to={"/customers/" + customerID + "/edit"} className="btn btn-primary btn-rounded"><i className="fa fa-plus"></i> Edit Customer</Link> &nbsp;
                    <button className="btn btn-danger btn-sm btn-rounded" onClick={deleteCustomer}><i className="fa fa-trash"></i> Delete Customer</button>
                </div>
            </div>
            <div className="card-box profile-header">
                <div className="row">
                    <div className="col-md-12">
                        {
                            customer &&
                        <div className="profile-view">
                            <div className="profile-img-wrap">
                                <div className="profile-img">
                                    <a href="#"><img className="avatar" src={user} alt="" /></a>
                                </div>
                            </div>
                            <div className="profile-basic">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="profile-info-left">
                                            <h3 className="user-name m-t-0 mb-0">{customer.name} </h3>
                                            <small className="text-muted">{customer.occupation}</small>
                                            <div className="staff-id">customer ID : PR-{customer.id && customer.id.toString().padStart(5, "0")}</div>
                                            <div className="staff-msg">
                                                {/* <Link to={"/record-sale?cid=" + customerID} className="btn btn-primary">Share <i className="fa fa-share"></i></Link> &nbsp; */}
                                                {/* <Link to={"/send-message?cid=" + customer.id} className="btn btn-outline-primary">Send Message</Link> */}
                                                </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <ul className="personal-info">
                                            <li>
                                                <span className="title">Phone:</span>
                                                <span className="text"><big>{customer.phone}</big></span>
                                            </li>
                                            <li>
                                                <span className="title">Address:</span>
                                                <span className="text">{customer.address}</span>
                                            </li>
                                            <li>
                                                <span className="title">Email:</span>
                                                <span className="text">{customer.email || "Not provided"}</span>
                                            </li>
                                            <li>
                                                <span className="title">Purchases:</span>
                                                <span className="text">{customer.purchases || "No purchase yet"}</span>
                                            </li>
                                            <li>
                                                <span className="title">Last Purchase:</span>
                                                <span className="text">{customer.purchases ? new Date(customer.last_purchase).toDateString() : "No purchase yet"}</span>
                                            </li>
                                            <li>
                                                <span className="title">Date Registered:</span>
                                                <span className="text">{new Date(customer.date_added).toDateString()}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>  
                        }                      
                    </div>
                </div>
            </div>
            <div className="profile-tabs">
                <ul className="nav nav-tabs nav-tabs-bottom">
                    <li className="nav-item"><a className="nav-link active" href="#about-cont" data-toggle="tab">Purchases</a></li>
                    {/* <li className="nav-item"><a className="nav-link" href="#bottom-tab2" data-toggle="tab">Messages</a></li> */}
                </ul>

                <div className="tab-content">
                    <div className="tab-pane show active" id="about-cont">
                        <div className="row">
                            {
                                (customer && customer.sales) &&
                            <div className="col-md-12">
                                <div className="card-box">
                                    <h3 className="card-title">Purchases Records</h3>
                                    <div className="experience-box">
                                        {
                                            (customer.sales && customer.sales.length > 0) && ( 
                                            <ul className="experience-list">
                                                {
                                                    customer.sales.sort((a, b) => new Date(b.date_recorded).getTime() - new Date(a.date_recorded).getTime()).map(sale => {
                                                        return (
                                                        <li>
                                                            <div className="experience-user">
                                                                <div className="before-circle"></div>
                                                            </div>
                                                            <div className="experience-content">
                                                                <div className="timeline-content">
                                                                    <Link to={`/sales/${sale.id}?sid=${sale.salesid}`} className="name">#{sale.salesid}</Link>
                                                                    <div>&#8358;{sale.total_amount.toLocaleString(4, "0")}</div>
                                                                    <span className="time">{new Date(sale.date_recorded).toDateString()}</span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="tab-pane" id="bottom-tab2">
                        <div className="row">
                            {/* <div className="col-md-12">
                                {
                                    
                                (customer && customer.messages) &&
                                <div className="card-box">
                                    <h3 className="card-title">All Messages</h3>
                                    <div className="experience-box">
                                        <ul className="experience-list">
                                            <li>
                                                <div className="experience-user">
                                                    <div className="before-circle"></div>
                                                </div>
                                                <div className="experience-content">
                                                    <div className="timeline-content">
                                                        <Link to="/sales/123" className="name">Welcome to Soft Collections..</Link>
                                                        <div>Welcome Message</div>
                                                        <span className="time">June 12, 2021</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="experience-user">
                                                    <div className="before-circle"></div>
                                                </div>
                                                <div className="experience-content">
                                                    <div className="timeline-content">
                                                        <Link to="/sales/123" className="name">Welcome to Soft Collections..</Link>
                                                        <div>Welcome Message</div>
                                                        <span className="time">June 12, 2021</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="experience-user">
                                                    <div className="before-circle"></div>
                                                </div>
                                                <div className="experience-content">
                                                    <div className="timeline-content">
                                                        <Link to="/sales/123" className="name">Welcome to Soft Collections..</Link>
                                                        <div>Welcome Message</div>
                                                        <span className="time">June 12, 2021</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="experience-user">
                                                    <div className="before-circle"></div>
                                                </div>
                                                <div className="experience-content">
                                                    <div className="timeline-content">
                                                        <Link to="/sales/123" className="name">Welcome to Soft Collections..</Link>
                                                        <div>Welcome Message</div>
                                                        <span className="time">June 12, 2021</span>
                                                    </div>
                                                </div>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </div>

                                }
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </BodyWrapper>
    )
}

export default CustomerProfile;